import * as React from "react";
import * as z from "zod";
import axios from "axios";
import {useQuery} from "react-query";
import {TextField, Autocomplete, LinearProgress, Typography, Box} from "@mui/material";
import {useNavigate, useParams} from "react-router-dom";

const SessionValidator = z.array(z.object({id: z.string().min(5).max(10), name: z.string()}));
const SessionData = () => {
    const params = useParams();
    return useQuery(
        ["get-session"],
        async () => {
            const res = await axios
                .get(`/outil_formation/api/formateurs/${params.id}/sessions/${params.societe}`)
                .then((response) => response.data.instructor.trainingSessions);
            return SessionValidator.parse(res);
        },
        {
            onError: (err) => {
                console.error("Fetch API error", err);
            },
        }
    );
};
export default function Sessions() {
    const {isLoading, isError, data} = SessionData();
    const params = useParams();
    let navigate = useNavigate();
    if (isLoading) return <LinearProgress/>;
    if (isError) return <div>Error</div>;
    if (data) {
        return (
            <>
                <Typography display="flex" justifyContent="center" alignItems="center">
                    Dtalents {params.societe}
                </Typography>
                <Box display="flex" justifyContent="center" alignItems="center" marginTop={"2%"}>
                    <Autocomplete
                        onChange={(e, value) => {
                            if (value !== null) {
                                navigate(`/formateurs/${params.id}/session/${value?.id}/${params.societe}`);
                            }
                        }}
                        disablePortal
                        id="box-formateurs"
                        options={data}
                        sx={{width: 300}}
                        getOptionLabel={(option) => option?.name + " - " + option?.id}
                        renderInput={(params) => <TextField {...params} label="Sessions"/>}
                    />
                </Box>
            </>
        );
    }
}
