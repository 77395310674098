/**
 *
 *
 * TODO Trop de redeclation de type/const dans les fichier
 * TODO petit d'affichage d'une erreur (selection deselection des dates la verif est bien faite c'est juste visuelle)
 *
 *
 */
import * as React from "react";
import axios from "axios";
import * as z from "zod";
import {zodResolver} from "@hookform/resolvers/zod";
import {QueryClient, QueryClientProvider, useMutation, useQuery} from "react-query";
import {Control, Controller, useFieldArray, useForm, useWatch} from "react-hook-form";
import {useNavigate, useParams} from "react-router-dom";
import {
    Autocomplete,
    Box,
    Button,
    Checkbox,
    Chip,
    Divider,
    FilledTextFieldProps,
    FormControlLabel,
    Grid,
    IconButton,
    LinearProgress,
    MenuItem,
    OutlinedTextFieldProps,
    Snackbar,
    Stack,
    StandardTextFieldProps,
    TextField,
    TextFieldVariants,
    Tooltip,
    Typography,
} from "@mui/material";
import MuiAlert, {AlertProps} from "@mui/material/Alert";
import {AiFillCaretDown, AiFillCaretUp} from "react-icons/ai";
import {Add, Delete, Home} from "@mui/icons-material";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import frLocale from "date-fns/locale/fr";
import styled from "styled-components";
import Format from "date-fns/format";

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
const ArticlesConsulting = [{article: "Articles", value: "None"}, {
    article: "Frais prestation France",
    value: "F"
}, {article: "Frais export", value: "FR_E"}, {article: "Frais DOM", value: "F_DOM"}, {
    article: "Prestation France",
    value: "P"
}, {article: "Prestation Export", value: "PE"}, {article: "Prestation DOM", value: "P_DOM"}, {
    article: "Refacturation de charges",
    value: "REFACT"
}, {article: "Escompte", value: "ZESCOMPTE"}, {article: "Port & Frais d'expédition", value: "ZPORT"}];

const ArticlesFormations = [{article: "Articles", value: "None"}, {
    article: "Convention de formation",
    value: "CF"
}, {article: "Convention de formation porté", value: "CFP"}, {article: "Frais", value: "F"}, {
    article: "Formation export",
    value: "FE"
}, {article: "Formation export porté", value: "FEP"}, {article: "Frais de formation", value: "FF"}, {
    article: "Frais de formation export",
    value: "FFE"
}, {article: "Frais de formation export porté", value: "FFEP"}, {
    article: "Frais de formation porté",
    value: "FFP"
}, {article: "Frais porté", value: "FP"}, {article: "Frais export", value: "FRE"}, {
    article: "Frais export porté",
    value: "FR_EP"
}, {article: "Prestation", value: "P"}, {
    article: "Prestation export",
    value: "PE"
}, {article: "Prestation export porté", value: "PEP"}, {article: "Prestation porté", value: "PP"}, {
    article: "TEST",
    value: "TEST"
}];
//*override du cursor MUI
const SubmitTextField = styled(TextField)`
  .MuiOutlinedInput-input {
    cursor: pointer;
  }
`;
type FormValues = {
    line: {
        article: string;
        designation: string;
        pu: number;
        quantite: number;
    }[];
};
const factureForm = z.object({
    line: z
        .array(
            z.object({
                article: z.string().min(1, {message: "Article Obligatoire."}),
                designation: z.string().min(1, {message: "Designation Obligatoire."}),
                pu: z
                    .number({
                        required_error: "Pu Obligatoire",
                        invalid_type_error: "Pu must be a number",
                    })
                    .nonnegative({message: "Pu must be non-negative"}),
                quantite: z
                    .number({
                        required_error: "Quantite required",
                        invalid_type_error: "Quantite must be a number",
                    })
                    .nonnegative({message: "Quantite must be non-negative"}),
            })
        )
        //* Regle de validation lié à l'import sage
        .superRefine((val, ctx) => {
            val.forEach((val, index) => {
                if (val.quantite > 0) {
                    if (val.pu === 0) {
                        ctx.addIssue({
                            code: z.ZodIssueCode.custom,
                            message: `Pu Obligatoire.`,
                            path: [index, "pu"],
                        });
                    }
                    if (val.article === "None") {
                        ctx.addIssue({
                            code: z.ZodIssueCode.custom,
                            message: `Article Obligatoire.`,
                            path: [index, "article"],
                        });
                    }
                }
                if (val.pu > 0 && val.quantite === 0) {
                    if (val.quantite === 0) {
                        ctx.addIssue({
                            code: z.ZodIssueCode.custom,
                            message: `Quantite Obligatoire.`,
                            path: [index, "quantite"],
                        });
                    }
                    if (val.article === "None") {
                        ctx.addIssue({
                            code: z.ZodIssueCode.custom,
                            message: `Article Obligatoire.`,
                            path: [index, "article"],
                        });
                    }
                }
                if (val.article !== "None") {
                    if (val.quantite === 0) {
                        ctx.addIssue({
                            code: z.ZodIssueCode.custom,
                            message: `Quantite Obligatoire.`,
                            path: [index, "quantite"],
                        });
                    }
                    if (val.pu === 0) {
                        ctx.addIssue({
                            code: z.ZodIssueCode.custom,
                            message: `Pu Obligatoire.`,
                            path: [index, "pu"],
                        });
                    }
                }
            });
        }),
});
type FormData = z.infer<typeof factureForm>;
let TotalRecap = 0;
const Total = ({control}: { control: Control<FormData> }) => {
    const formValues = useWatch({
        name: "line",
        control,
    });
    const total = formValues.reduce((acc, current) => acc + (current.pu || 0) * (current.quantite || 0), 0);
    TotalRecap = total;
    return (
        <Box textAlign="right" sx={{pr: "4%"}}>
            <Typography>Total HT: {total}</Typography>
        </Box>
    );
};

/**
 * * *************************BAS DE LA FACTURE
 * @param props
 * @returns
 */
function BasFacture(props: {
    lines: { article: string; designation: string; pu: number; quantite: number }[];
    data: {
        designation: string[];
        article: string[];
        quantite: number[];
        pu: number[];
        id: number[];
        totalht: number;
        session: string;
        datetext: string;
        instructor: string;
        customer: string;
        stagiaires: number;
        dateRecap: string;
        date: string;
        formateur_sage: number;
        client_sage: number;
    };
}) {
    const params = useParams();
    const mutationPost = useMutation(
        (out: {
            instructor: string;
            customer: string;
            exported: boolean;
            session: string;
            datetext: string;
            date: string;
            nbStagiaires: number;
            totalht: string;
            numFactSage: number;
            instructorMatricule: number;
            customerMatricule: number;
            societe: string;
            lignes: { designation: string; article: string; pu: string; quantite: string; position: number; }[];
        }) => {
            setOpen(true);
            return axios.post(`/api/factures`, out).then((res) => {
                console.log(res.data.id);
            });
        }
    );
    const [lines, setLines] = React.useState(props.lines);
    const [recap, setRecap] = React.useState(props.data);
    const [open, setOpen] = React.useState(true);
    const {
        register,
        control,
        handleSubmit,
        formState: {errors},
    } = useForm<FormData>({
        defaultValues: {
            line: lines,
        },
        mode: "onBlur",
        resolver: zodResolver(factureForm),
    });
    const {fields, append, remove, move} = useFieldArray({
        name: "line",
        control,
    });
    const onSubmit = (data: FormValues) => {
        setOpen(false);
        //console.log("onSubmit Bas", data);
        let lignes: { designation: string; article: string; pu: string; quantite: string; position: number; }[] = [];
        data.line.forEach((element, index) => {
            lignes.push({
                designation: element.designation,
                article: element.article,
                pu: element.pu.toString(),
                quantite: element.quantite.toString(),
                position: index,
            })
        });
        const out = {
            instructor: recap.instructor,
            customer: recap.customer,
            exported: false,
            session: recap.session,
            datetext: recap.datetext,
            date: recap.dateRecap,
            nbStagiaires: recap.stagiaires,
            totalht: TotalRecap.toString(),
            instructorMatricule: recap.formateur_sage,
            customerMatricule: recap.client_sage,
            numFactSage: 0,
            //ça ne peut pas arriver c'est pour éviter l'erreur TS
            societe: params?.societe ? params?.societe : "",
            lignes: lignes,
        }
        mutationPost.mutate(out);
        console.log(out);
    };
    const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === "clickaway") {
            return;
        }

        setOpen(false);
    };
    return (
        <>
            {mutationPost.isSuccess ? (
                <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}
                          anchorOrigin={{vertical: "top", horizontal: "center"}}>
                    <Alert onClose={handleClose} severity="success" sx={{width: "100%"}}>
                        La facture est bien sauvegardée !
                    </Alert>
                </Snackbar>
            ) : null}
            {mutationPost.isError ? (
                <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}
                          anchorOrigin={{vertical: "top", horizontal: "center"}}>
                    <Alert onClose={handleClose} severity="error" sx={{width: "100%"}}>
                        Erreur lors de la sauvegarde de la facture.
                    </Alert>
                </Snackbar>
            ) : null}
            <form onSubmit={handleSubmit(onSubmit)}>
                <Divider sx={{pb: "2%"}}>
                    <Chip label="Bas de la facture" variant="outlined" color="info"/>
                </Divider>
                <table>
                    <thead>
                    <tr>
                        <th style={{fontFamily: '"Roboto","Helvetica","Arial",sans-serif'}}>Trier</th>
                        <th style={{fontFamily: '"Roboto","Helvetica","Arial",sans-serif'}}>Article</th>
                        <th style={{fontFamily: '"Roboto","Helvetica","Arial",sans-serif'}}>Désignation</th>
                        <th style={{fontFamily: '"Roboto","Helvetica","Arial",sans-serif'}}>P.U. HT</th>
                        <th style={{fontFamily: '"Roboto","Helvetica","Arial",sans-serif'}}>Quantité</th>
                        <th style={{fontFamily: '"Roboto","Helvetica","Arial",sans-serif'}}>Action</th>
                    </tr>
                    </thead>
                    <tbody>
                    {fields.map((field, index) => {
                        return (
                            <tr key={field.id}>
                                <td style={{textAlign: "center"}}>
                                    <a href="#a" className="up" onClick={() => move(index, index - 1)}>
                                        <AiFillCaretUp/>
                                    </a>
                                    <a href="#a" className="down" onClick={() => move(index, index + 1)}>
                                        <AiFillCaretDown/>
                                    </a>
                                </td>
                                <td>
                                    <Box>
                                        <TextField
                                            size="small"
                                            select
                                            defaultValue={lines?.[index]?.article ? lines[index].article : "None"}
                                            inputProps={register(`line.${index}.article`, {
                                                required: true,
                                            })}
                                            error={!!errors?.line?.[index]?.article}
                                            helperText={errors?.line?.[index]?.article?.message}
                                        >
                                            {params.societe == 'formations' ? ArticlesFormations.map((article) => {
                                                return <MenuItem value={article.value}>{article.article}</MenuItem>
                                            }) : ArticlesConsulting.map((article) => {
                                                return <MenuItem value={article.value}>{article.article}</MenuItem>
                                            })}
                                        </TextField>
                                    </Box>
                                </td>
                                <td style={{width: "100%"}}>
                                    <Box component="div">
                                        <TextField
                                            sx={{width: "100%"}}
                                            size="small"
                                            placeholder="designation"
                                            {...register(`line.${index}.designation` as const, {
                                                required: true,
                                            })}
                                            aria-invalid={errors.line?.[index]?.designation ? "true" : "false"}
                                            error={!!errors?.line?.[index]?.designation}
                                            helperText={errors?.line?.[index]?.designation?.message}
                                        />
                                    </Box>
                                </td>
                                <td>
                                    <TextField
                                        size="small"
                                        placeholder="pu"
                                        style={{minWidth: "150px"}}
                                        {...register(`line.${index}.pu` as const, {
                                            valueAsNumber: true,
                                            required: true,
                                        })}
                                        aria-invalid={errors.line?.[index]?.pu ? "true" : "false"}
                                        error={!!errors?.line?.[index]?.pu}
                                        helperText={errors?.line?.[index]?.pu?.message}
                                    />
                                </td>
                                <td>
                                    <TextField
                                        size="small"
                                        placeholder="quantite"
                                        style={{minWidth: "80px"}}
                                        {...register(`line.${index}.quantite` as const, {
                                            valueAsNumber: true,
                                            required: true,
                                        })}
                                        aria-invalid={errors.line?.[index]?.quantite ? "true" : "false"}
                                        error={!!errors?.line?.[index]?.quantite}
                                        helperText={errors?.line?.[index]?.quantite?.message}
                                    />
                                </td>
                                <td style={{textAlign: "center"}}>
                                    <IconButton aria-label="delete" size="medium" color="error"
                                                onClick={() => remove(index)}>
                                        <Delete fontSize="medium"/>
                                    </IconButton>
                                </td>
                            </tr>
                        );
                    })}
                    </tbody>
                </table>
                <Total control={control}/>
                <Button
                    variant="outlined"
                    startIcon={<Add/>}
                    size="small"
                    onClick={() =>
                        append({
                            article: `None`,
                            designation: "",
                            quantite: 0,
                            pu: 0,
                        })
                    }
                >
                    Ligne
                </Button>
                <Box textAlign="center" sx={{pt: "2%", pb: "2%"}}>
                    <SubmitTextField type="submit" value="Enregistrer la facture"/>
                </Box>
            </form>
        </>
    );
}

const QuantityRules = (cm: string, nbrdate: number, seconds: number, nbrstagiaires: number) => {
    let quantity = 0;
    switch (cm) {
        case "per_customer": //Prix par client -> per_customer (x quantité = x client)
            quantity = 1; //always ?
            break;
        case "per_customer_day_indivisible": //Prix par client par date -> per_customer_day_indivisible (x quantité = x demi journée SEULE OU x journée) => CLC
            quantity = nbrdate / 2;
            break;
        case "per_customer_day": //Prix par client par jour 0.5 par créneaux -> per_customer_day (1 halfday = 0.5 quantite)
            quantity = nbrdate * 0.5;
            break;
        case "per_customer_hour": //Prix par client par heure -> per_customer_hour (x quantité = x heure)
            quantity = parseFloat(secondToHoursMins(seconds).replace(":", "."));
            break;
        case "per_trainee": //Prix par apprenant -> per_trainee (x quantité = x apprenant)
            quantity = nbrstagiaires;
            break;
        case "per_day_indivisible": //Prix par apprenant par date -> per_day_indivisible (x quantité = (x demi journée SEULE OU x journée)* z apprenant) => CLC
            quantity = (nbrdate / 2) * nbrstagiaires;
            break;
        case "per_day": //Prix pas apprenant par jour 0.5 -> per_day (p quantité = x demi journée * z apprenant)
            quantity = nbrdate * 0.5 * nbrstagiaires;
            break;
        case "per_hour": //Prix par apprenant par heure -> per_hour (x quantité = x heure * z apprenant)
            quantity = parseFloat(secondToHoursMins(seconds).replace(":", ".")) * nbrstagiaires;
            break;
    }
    return quantity;
};
const Seconds = (arg: string) => {
    const parts = arg.split(":");
    return parseInt(parts[0]) * 3600 + parseInt(parts[1]) * 60 + parseInt(parts[2]);
};
const secondToHoursMins = (seconds: number) => {
    const sec = String(Math.floor((seconds / 60) % 60) / 60).substring(2) == "" ? "" : "," + String(Math.floor((seconds / 60) % 60) / 60).substring(2);
    return Math.floor(seconds / 3600) + sec;
};
const isTrue = (element: boolean) => element;
const HautFactureSchema = z.object({
    formateur: z.string().min(1, {message: "Formateur Obligatoire."}).max(200),
    client: z.string().min(1, {message: "Client Obligatoire."}).max(200),
    date_facturation: z
        .date()
        .nullable()
        .superRefine((val, ctx) => {
            if (val === null) {
                //* contre intuitif mais oblige car MUI date doit etre null a l'init
                ctx.addIssue({
                    code: z.ZodIssueCode.custom,
                    message: `Date Obligatoire.`,
                });
            }
        }),
    nombre_stagiaires: z.nan().or(
        z
            .number({
                required_error: "Nombre de stagiaires Obligatoire.",
            })
            .nonnegative({message: "Nombre de stagiaires must be non-negative"})
    ),
    date_formation: z.array(z.boolean()).refine((array) => array.some(isTrue), "Sélectionner au moins une date."),
    formateur_sage: z.number({
        required_error: "Formateur Sage Obligatoire.",
    }),
    client_sage: z.number({
        required_error: "Client Sage Obligatoire.",
    }),
});
type FormDataHaut = z.infer<typeof HautFactureSchema>;

/**
 * * **************************HAUT DE LA FACTURE
 * @param props
 * @returns
 */
function HautFacture(props: {
    data: { uniqueSession: DigiformaType, listeFormateurWork: FormateurListeType, listeClientSage: ClientListeType }
}) {
    const params = useParams();
    const [numberStagiaire, setNumberStagiaire] = React.useState<number>(props.data.uniqueSession.customers[0].customerTrainees.length);
    const [checked, setChecked] = React.useState(false);
    const [recap, setRecap] = React.useState<{
        designation: string[];
        article: string[];
        quantite: number[];
        pu: number[];
        id: number[];
        totalht: number;
        session: string;
        datetext: string;
        instructor: string;
        customer: string;
        stagiaires: number;
        dateRecap: string;
        date: string;
        formateur_sage: number;
        client_sage: number;
    }>({
        designation: [],
        article: [],
        quantite: [],
        pu: [],
        id: [],
        totalht: 0,
        session: "",
        datetext: "",
        customer: "",
        instructor: "",
        stagiaires: 0,
        dateRecap: "",
        date: "",
        formateur_sage: 0,
        client_sage: 0,
    });
    const [lines, setLines] = React.useState<{
        article: string;
        designation: string;
        pu: number;
        quantite: number
    }[]>([]);
    const [formSub, setFormSub] = React.useState(false);
    const {
        register,
        handleSubmit,
        control,
        setValue,
        formState: {errors},
    } = useForm<FormDataHaut>({
        defaultValues: {date_formation: Array.from({length: props.data.uniqueSession.dates.length}, () => false)},
        resolver: zodResolver(HautFactureSchema),
    });
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setChecked(event.target.checked);
        props.data.uniqueSession.dates.forEach((element, index) => {
            setValue(`date_formation.${index}`, event.target.checked, {
                shouldValidate: true,
                shouldDirty: true,
                shouldTouch: true
            });
        });
    };
    let navigate = useNavigate();
    const Changeclient = (client: string) => {
        props.data.uniqueSession.customers.forEach((element) => {
            if (element.entity.name === client) {
                setNumberStagiaire(element.customerTrainees.length);
            }
        });
    };
    const lastDayMonth = new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0);
    /**
     * *Traitement des données soumises pour génération bas de la facture
     * @param data
     */
    const onSubmit = (data: FormDataHaut) => {
        console.log("onSubmit Haut", data);
        const dateArr: string[] = [];
        let seconds = 0;
        data.date_formation.forEach((element, index) => {
            if (element) {
                dateArr.push(props.data.uniqueSession.dates[index].date);
                seconds += Seconds(props.data.uniqueSession.dates[index].endTime) - Seconds(props.data.uniqueSession.dates[index].startTime);
            }
        });
        // @ts-ignore
        const setDatesStatic = [...new Set(dateArr)];
        const DatesStatic = setDatesStatic.length == 1 ? "Date : " + setDatesStatic.join(", ") : "Dates : " + setDatesStatic.join(", ");
        const TimeStatic = "Durée : " + secondToHoursMins(seconds) + " heures";
        let countStagiaire = 0;
        if (isNaN(data.nombre_stagiaires)) {
            props.data.uniqueSession.customers.forEach((element) => {
                if (element.entity.name == data.client) {
                    countStagiaire = element.customerTrainees.length;
                }
            });
        } else {
            countStagiaire = data.nombre_stagiaires;
        }
        const TraineeStatic = countStagiaire == 0 ? null : countStagiaire == 1 ? "Stagiaire : " + countStagiaire + " personne" : "Stagiaires : " + countStagiaire + " personnes";
        const formlist = [];
        for (let idIns in props.data.uniqueSession.instructors) {
            formlist.push(props.data.uniqueSession.instructors[idIns].firstname + " " + props.data.uniqueSession.instructors[idIns].lastname);
        }
        const FormateurStatic = formlist.length == 1 ? "Formateur : " + formlist.join(", ") : "Formateurs : " + formlist.join(", ");
        const ConventionFormation = 'Convention de formation du ';
        //? infer le type le soucis est que des fois ca re render pas vu que la structure change pas
        const recapLoc: {
            designation: string[];
            article: string[];
            quantite: number[];
            pu: number[];
            id: number[];
            totalht: number;
            session: string;
            datetext: string;
            instructor: string;
            customer: string;
            stagiaires: number;
            dateRecap: string;
            date: string;
            formateur_sage: number;
            client_sage: number;
        } = {
            designation: [],
            article: [],
            quantite: [],
            pu: [],
            id: [],
            totalht: 0,
            session: "",
            datetext: "",
            instructor: "",
            customer: "",
            stagiaires: 0,
            dateRecap: "",
            date: "",
            formateur_sage: 0,
            client_sage: 0,
        };
        //pas beau
        recapLoc.designation.push(ConventionFormation);
        recapLoc.pu.push(0);
        recapLoc.quantite.push(0);
        recapLoc.article.push("None");
        //mtn que le formulaire est géré par rhf (field array) l'id ne sert plus
        recapLoc.id.push(0);
        let i = 0, len = props.data.uniqueSession.costs.length;
        for (; i < len; i++) {
            const quantity = QuantityRules(props.data.uniqueSession.costs[i].costMode, dateArr.length, seconds, countStagiaire);
            recapLoc.designation.push(props.data.uniqueSession.costs[i].description);
            recapLoc.pu.push(props.data.uniqueSession.costs[i].cost);
            recapLoc.quantite.push(quantity);
            recapLoc.article.push("None");
            recapLoc.totalht += props.data.uniqueSession.costs[i].cost * quantity;
            recapLoc.id.length == 0 ? recapLoc.id.push(0) : recapLoc.id.push(recapLoc.id.length);
        }
        if (TraineeStatic == null) {
            recapLoc.designation.push(...[DatesStatic, TimeStatic, FormateurStatic]);
            recapLoc.article.push(...["None", "None", "None"]);
            recapLoc.pu.push(...[0, 0, 0]);
            recapLoc.quantite.push(...[0, 0, 0]);
            recapLoc.id.push(...[recapLoc.id.length, recapLoc.id.length + 1, recapLoc.id.length + 2]);
        } else {
            recapLoc.designation.push(...[DatesStatic, TimeStatic, TraineeStatic, FormateurStatic]);
            recapLoc.article.push(...["None", "None", "None", "None"]);
            recapLoc.pu.push(...[0, 0, 0, 0]);
            recapLoc.quantite.push(...[0, 0, 0, 0]);
            recapLoc.id.push(...[recapLoc.id.length, recapLoc.id.length + 1, recapLoc.id.length + 2, recapLoc.id.length + 3]);

        }
        recapLoc.session = props.data.uniqueSession.name;
        // @ts-ignore
        recapLoc.datetext = [...new Set(dateArr)].join(", ");
        recapLoc.customer = data.client;
        recapLoc.instructor = data.formateur;
        recapLoc.stagiaires = countStagiaire;
        recapLoc.formateur_sage = data.formateur_sage;
        recapLoc.client_sage = data.client_sage;

        //*forcement !null car super refine => avoid ts error
        if (data.date_facturation !== null) {
            recapLoc.dateRecap = Format(data.date_facturation, "dd-MM-yyyy");
            recapLoc.date = Format(data.date_facturation, "ddMMyy");
        }
        const linesWait: { designation: string; pu: number; article: string; quantite: number }[] = [];
        recapLoc.article.forEach((element, index) => {
            linesWait.push({
                article: element,
                designation: recapLoc.designation[index],
                quantite: recapLoc.quantite[index],
                pu: recapLoc.pu[index]
            });
        });
        setLines(linesWait);
        setRecap(recapLoc);
        setFormSub(true);
    };
    return (
        <>
            <Box sx={{
                position: "sticky",
                pt: "1%",
                top: "0px",
                bgcolor: "white",
                opacity: [1, 1, 1],
                textAlign: "center"
            }}>
                <Tooltip title="Maison">
                    <IconButton onClick={() => navigate("/#" + params.societe)}>
                        <Home color="info"/>
                    </IconButton>
                </Tooltip>
                <Chip
                    label={props.data.uniqueSession.name + " - Dtalents " + params.societe}
                    variant="outlined"
                    sx={{ml: "1%", fontSize: "1em"}}
                    size="medium"
                    color="info"
                />
            </Box>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Divider sx={{pb: "1%", pt: "2%"}}>
                    <Chip
                        label="Formateur, Client, Date de la facture, Nombre de stagiaire(s)"
                        variant="outlined"
                        color="info"
                        sx={{bgcolor: "white", opacity: [1, 1, 1]}}
                    />
                </Divider>
                <Stack direction="row" divider={<Divider orientation="vertical" flexItem/>} spacing={2}>
                    <TextField
                        fullWidth
                        label="Formateur Digiforma"
                        select
                        defaultValue={props.data.uniqueSession.instructors.length == 1 ? props.data.uniqueSession.instructors[0].lastname : ''}
                        inputProps={register(`formateur`, {
                            required: true,
                        })}
                        error={!!errors?.formateur}
                        helperText={errors?.formateur?.message}
                    >
                        {props.data.uniqueSession.instructors.map((instructor) => (
                            <MenuItem key={instructor.lastname} value={instructor.lastname}>
                                {instructor.lastname}
                            </MenuItem>
                        ))}
                    </TextField>
                    <TextField
                        fullWidth
                        label="Client Digiforma"
                        select
                        defaultValue={props.data.uniqueSession.customers.length == 1 ? props.data.uniqueSession.customers[0].entity.name : ''}
                        inputProps={register(`client`, {
                            required: true,
                        })}
                        error={!!errors?.client}
                        helperText={errors?.client?.message}
                        onChange={(e) => Changeclient(e.target.value)}
                    >
                        {props.data.uniqueSession.customers.map((customer) => (
                            <MenuItem key={customer.entity.name} value={customer.entity.name}>
                                {customer.entity.name}
                            </MenuItem>
                        ))}
                    </TextField>
                    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={frLocale}>
                        <Controller
                            name="date_facturation"
                            control={control}
                            defaultValue={lastDayMonth}
                            render={({field: {onChange, value}, fieldState: {error}}) => (
                                <DatePicker
                                    label="Date de facturation"
                                    value={value}
                                    onChange={(value) => onChange(value)}
                                    renderInput={(params) => (
                                        <TextField helperText={errors?.date_facturation?.message} fullWidth {...params}
                                                   error={!!errors?.date_facturation}/>
                                    )}
                                />
                            )}
                        />
                    </LocalizationProvider>
                    <TextField
                        label={`Nombre de stagiaire(s) : ${numberStagiaire}`}
                        type="number"
                        fullWidth
                        defaultValue={""}
                        {...register("nombre_stagiaires", {valueAsNumber: true, required: false})}
                        error={!!errors?.nombre_stagiaires}
                        helperText={
                            errors?.nombre_stagiaires
                                ? errors?.nombre_stagiaires?.message
                                : "Saisir ce champ uniquement si le nombre de stagiaire(s) doit être changé."
                        }
                    />
                </Stack>
                <Divider sx={{pb: "1%", pt: "2%"}}>
                    <Chip label="Formateur et Client Sage" variant="outlined" color="info"/>
                </Divider>
                <Box display="flex" justifyContent="center" alignItems="center">
                    <Stack direction="row" divider={<Divider orientation="vertical" flexItem/>} spacing={2}
                           sx={{width: "50%"}}>
                        <Controller
                            render={({field: {onChange, value}, fieldState: {error}}) => (
                                <Autocomplete
                                    disablePortal
                                    {...props}
                                    sx={{width: "100%"}}
                                    onChange={(e, value) => onChange(Number(value?.matricule))}
                                    options={props.data.listeFormateurWork}
                                    getOptionLabel={(option) => option.prenom + ' ' + option.nom}
                                    renderInput={(params) => (
                                        <TextField
                                            helperText={errors?.formateur_sage?.message}
                                            {...params}
                                            label="Formateur Sage"
                                            variant="outlined"
                                            error={!!errors?.formateur_sage}
                                        />
                                    )}
                                />
                            )}
                            name="formateur_sage"
                            control={control}
                        />
                        <Controller
                            render={({field: {onChange, value}, fieldState: {error}}) => (
                                <Autocomplete
                                    disablePortal
                                    {...props}
                                    sx={{width: "100%"}}
                                    onChange={(e, value) => onChange(Number(value?.CT_Num))}
                                    options={props.data.listeClientSage}
                                    getOptionLabel={(option) => option.CT_Intitule + '-' + option.CT_Num}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            error={!!errors?.client_sage}
                                            helperText={errors?.client_sage?.message}
                                            label="Client Sage"
                                            variant="outlined"
                                        />
                                    )}
                                />
                            )}
                            name="client_sage"
                            control={control}
                        />
                    </Stack>
                </Box>
                <Divider sx={{pt: "2%"}}>
                    <Chip label="Date(s) de la session" variant="outlined" color="info"/>
                </Divider>
                <Box sx={{flexGrow: 1, pt: "2%"}} id="CheckBoxDates">
                    <FormControlLabel
                        sx={{mb: '1%'}}
                        label={checked ? "Tout dé-sélectionner" : "Tout sélectionner"}
                        control={<Checkbox checked={checked} onChange={handleChange}
                                           inputProps={{"aria-label": "controlled"}}/>}
                    />
                    <Grid container spacing={{xs: 2, md: 3}} columns={{xs: 4, sm: 8, md: 12}}>
                        {props.data.uniqueSession.dates.map((date, index) => (
                            <Grid item xs={3} key={index}>
                                <Controller
                                    control={control}
                                    name={`date_formation.${index}`}
                                    render={({field: {onChange, value}}) => {
                                        return (
                                            <FormControlLabel
                                                sx={{fontSize: "0.3px"}}
                                                label={date.date + ", " + date.startTime.slice(0, -3) + " => " + date.endTime.slice(0, -3)}
                                                key={index}
                                                control={<Checkbox checked={value} onChange={onChange}/>}
                                            />
                                        );
                                    }}
                                />
                            </Grid>
                        ))}
                    </Grid>
                </Box>
                {errors?.date_formation && <Typography color={"error"}>{errors?.date_formation?.message}</Typography>}
                <Box textAlign="center" sx={{pt: "2%", pb: "2%"}}>
                    <SubmitTextField type="submit" value="Générer le bas de la facture"
                                     onClick={() => setFormSub(false)}/>
                </Box>
            </form>
            {formSub && <BasFacture lines={lines} data={recap}/>}
        </>
    );
}

const DigiformaDataValidator = z.object({
    costs: z.array(z.object({cost: z.number(), costMode: z.string(), description: z.string(), type: z.string()})),
    customers: z.array(
        z.object({
            customerTrainees: z.array(z.object({trainee: z.object({lastname: z.string()})})),
            entity: z.object({__typename: z.string(), name: z.string()}),
        })
    ),
    dates: z.array(z.object({date: z.string(), endTime: z.string(), startTime: z.string()})),
    endDate: z.string(),
    instructors: z.array(z.object({firstname: z.string(), lastname: z.string()})),
    name: z.string(),
    startDate: z.string(),
    trainees: z.array(z.object({id: z.string()})),
});
type DigiformaType = z.infer<typeof DigiformaDataValidator>;
const FormateurListeValidator = z.array(z.object({
    id: z.number(),
    nom: z.string(),
    prenom: z.string(),
    matricule: z.string(),
}));
type FormateurListeType = z.infer<typeof FormateurListeValidator>;
const ClientListeValidator = z.array(z.object({
    CT_Num: z.string(),
    CT_Intitule: z.string(),
}));
type ClientListeType = z.infer<typeof ClientListeValidator>;
const DigiformaData = () => {
    const params = useParams();
    return useQuery(
        ["get-digiforma"],
        async () => {
            const res = await axios.get(`/outil_formation/api/session/${params.idSes}/${params.societe}`).then((response) => response.data.trainingSession);
            const resFormateur = await axios.get(`/outil_formation/api/work/user`).then((response) => response.data);
            const resClient = await axios.get(`/outil_formation/api/formation/clients/${params.societe}`).then((response) => response.data);
            if (FormateurListeValidator.parse(resFormateur) && ClientListeValidator.parse(resClient)) {
                return {
                    uniqueSession: DigiformaDataValidator.parse(res),
                    listeFormateurWork: FormateurListeValidator.parse(resFormateur),
                    listeClientSage: ClientListeValidator.parse(resClient),
                };
            } else {
                return null;
            }
        },
        {
            onError: (err) => {
                console.error("Fetch API error", err);
            },
        }
    );
};

function Fetch() {
    const {isLoading, isError, data} = DigiformaData();
    if (isLoading) return <LinearProgress/>;
    if (isError) return <>Error.</>;
    if (data) {
        return <HautFacture data={data}/>;
    }
    return null;
}

const queryClient = new QueryClient({
    defaultOptions: {
        //*Static Data
        queries: {
            refetchOnWindowFocus: false,
            refetchOnReconnect: false,
        },
    },
});
export default function Starter() {
    return (
        <QueryClientProvider client={queryClient}>
            <Fetch/>
        </QueryClientProvider>
    );
}
