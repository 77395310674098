import * as React from "react";
import * as z from "zod";
import axios from "axios";
import {useQuery, QueryClient, QueryClientProvider, useMutation} from "react-query";
import {useForm, useFieldArray, useWatch, Control} from "react-hook-form";
import {TextField, MenuItem, LinearProgress, Box, Typography, Button, Snackbar, Tooltip} from "@mui/material";
import MuiAlert, {AlertProps} from "@mui/material/Alert";
import {zodResolver} from "@hookform/resolvers/zod";
import {Delete, Add, Home} from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import {AiFillCaretUp, AiFillCaretDown} from "react-icons/ai";
import {useNavigate, useParams} from "react-router-dom";
import styled from "styled-components";

const ArticlesConsulting = [{article: "Articles", value: "None"}, {
    article: "Frais prestation France",
    value: "F"
}, {article: "Frais export", value: "FR_E"}, {article: "Frais DOM", value: "F_DOM"}, {
    article: "Prestation France",
    value: "P"
}, {article: "Prestation Export", value: "PE"}, {article: "Prestation DOM", value: "P_DOM"}, {
    article: "Refacturation de charges",
    value: "REFACT"
}, {article: "Escompte", value: "ZESCOMPTE"}, {article: "Port & Frais d'expédition", value: "ZPORT"}];

const ArticlesFormations = [{article: "Articles", value: "None"}, {
    article: "Convention de formation",
    value: "CF"
}, {article: "Convention de formation porté", value: "CFP"}, {article: "Frais", value: "F"}, {
    article: "Formation export",
    value: "FE"
}, {article: "Formation export porté", value: "FEP"}, {article: "Frais de formation", value: "FF"}, {
    article: "Frais de formation export",
    value: "FFE"
}, {article: "Frais de formation export porté", value: "FFEP"}, {
    article: "Frais de formation porté",
    value: "FFP"
}, {article: "Frais porté", value: "FP"}, {article: "Frais export", value: "FRE"}, {
    article: "Frais export porté",
    value: "FR_EP"
}, {article: "Prestation", value: "P"}, {
    article: "Prestation export",
    value: "PE"
}, {article: "Prestation export porté", value: "PEP"}, {article: "Prestation porté", value: "PP"}, {
    article: "TEST",
    value: "TEST"
}];
type FormValues = {
    line: {
        article: string;
        designation: string;
        pu: number;
        quantite: number;
    }[];
};
const factureForm = z.object({
    line: z
        .array(
            z.object({
                article: z.string().min(1, {message: "Article Obligatoire."}),
                designation: z.string().min(1, {message: "Designation Obligatoire."}),
                pu: z
                    .number({
                        required_error: "Pu Obligatoire",
                        invalid_type_error: "Pu must be a number",
                    })
                    .nonnegative({message: "Pu must be non-negative"}),
                quantite: z
                    .number({
                        required_error: "Quantite Obligatoire",
                        invalid_type_error: "Quantite must be a number",
                    })
                    .nonnegative({message: "Quantite must be non-negative"}),
            })
        ) //* Regle de validation lié à l'import sage
        .superRefine((val, ctx) => {
            val.forEach((val, index) => {
                if (val.quantite > 0) {
                    if (val.pu === 0) {
                        ctx.addIssue({
                            code: z.ZodIssueCode.custom,
                            message: `Pu Obligatoire.`,
                            path: [index, "pu"],
                        });
                    }
                    if (val.article === "None") {
                        ctx.addIssue({
                            code: z.ZodIssueCode.custom,
                            message: `Article Obligatoire.`,
                            path: [index, "article"],
                        });
                    }
                }
                if (val.pu > 0 && val.quantite === 0) {
                    if (val.quantite === 0) {
                        ctx.addIssue({
                            code: z.ZodIssueCode.custom,
                            message: `Quantite Obligatoire.`,
                            path: [index, "quantite"],
                        });
                    }
                    if (val.article === "None") {
                        ctx.addIssue({
                            code: z.ZodIssueCode.custom,
                            message: `Article Obligatoire.`,
                            path: [index, "article"],
                        });
                    }
                }
                if (val.article !== "None") {
                    if (val.quantite === 0) {
                        ctx.addIssue({
                            code: z.ZodIssueCode.custom,
                            message: `Quantite Obligatoire.`,
                            path: [index, "quantite"],
                        });
                    }
                    if (val.pu === 0) {
                        ctx.addIssue({
                            code: z.ZodIssueCode.custom,
                            message: `Pu Obligatoire.`,
                            path: [index, "pu"],
                        });
                    }
                }
            });
        }),
});
type FormData = z.infer<typeof factureForm>;
const Total = ({control}: { control: Control<FormData> }) => {
    const formValues = useWatch({
        name: "line",
        control,
    });
    const total = formValues.reduce((acc, current) => acc + (current.pu || 0) * (current.quantite || 0), 0);
    return (
        <Box textAlign="right" sx={{pr: "4%"}}>
            <Typography>Total HT: {total}</Typography>
        </Box>
    );
};
//*overide du cursor MUI
const SubmitTextField = styled(TextField)`
  .MuiOutlinedInput-input {
    cursor: pointer;
  }
`;
const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function App(props: { data: Facture }) {
    const [open, setOpen] = React.useState(true);
    const params = useParams();
    const {
        register,
        control,
        handleSubmit,
        formState: {errors},
    } = useForm<FormData>({
        defaultValues: {
            line: props.data,
        },
        mode: "onBlur",
        resolver: zodResolver(factureForm),
    });
    let navigate = useNavigate();
    const {fields, append, remove, move} = useFieldArray({
        name: "line",
        control,
    });
    const mutationPut = useMutation((data: { totalht: string, lignes: { designation: string, quantite: string, pu: string, article: string, position: number }[] }) => {
        return axios.patch(`/api/factures/${params.id}`, data, {headers: {'content-type': 'application/merge-patch+json'}});
    });
    const onSubmit = (data: FormValues) => {
        console.log(data);
        let Lignes: { designation: string, quantite: string, pu: string, article: string, position: number }[] = [];
        let totalHt = 0;
        data.line.forEach((element, index) => {
            totalHt += element.quantite * element.pu;
            Lignes.push({
                designation: element.designation,
                quantite: String(element.quantite),
                pu: String(element.pu),
                article: element.article,
                position: index
            })
        })
        const out = {totalht: String(totalHt), lignes: Lignes};
        mutationPut.mutate(out);
        //navigate("/outil_formation/"); //*faudra voir à l'utilisation
    };
    const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === "clickaway") {
            return;
        }

        setOpen(false);
    };
    return (
        <>
            {mutationPut.isSuccess ? (
                <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}
                          anchorOrigin={{vertical: "top", horizontal: "center"}}>
                    <Alert onClose={handleClose} severity="success" sx={{width: "100%"}}>
                        La facture est bien modifiée !
                    </Alert>
                </Snackbar>
            ) : null}
            {mutationPut.isError ? (
                <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}
                          anchorOrigin={{vertical: "top", horizontal: "center"}}>
                    <Alert onClose={handleClose} severity="error" sx={{width: "100%"}}>
                        Erreur lors de la modification de la facture.
                    </Alert>
                </Snackbar>
            ) : null}
            <div>
                <Tooltip title="Maison">
                    <IconButton onClick={() => navigate("/#"+params.societe)}>
                        <Home color="info"/>
                    </IconButton>
                </Tooltip>
                <Typography>Dtalents {params.societe}</Typography>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <table className="">
                        <thead>
                        <tr>
                            <th>
                                <Typography style={{fontWeight: 600}}>Trier</Typography>
                            </th>
                            <th>
                                <Typography style={{fontWeight: 600}}>Article</Typography>
                            </th>
                            <th>
                                <Typography style={{fontWeight: 600}}>Désignation</Typography>
                            </th>
                            <th>
                                <Typography style={{fontWeight: 600}}>P.U. HT</Typography>
                            </th>
                            <th>
                                <Typography style={{fontWeight: 600}}>Quantité</Typography>
                            </th>
                            <th>
                                <Typography style={{fontWeight: 600}}>Action</Typography>
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        {fields.map((field, index) => {
                            return (
                                <tr key={field.id}>
                                    <td style={{textAlign: "center"}}>
                                        <a href="#" className="up" onClick={() => move(index, index - 1)}>
                                            <AiFillCaretUp/>
                                        </a>
                                        <a href="#" className="down" onClick={() => move(index, index + 1)}>
                                            <AiFillCaretDown/>
                                        </a>
                                    </td>
                                    <td>
                                        <Box sx={{overflow: 'scroll'}}>
                                            <TextField
                                                select
                                                defaultValue={props.data?.[index]?.article ? props.data[index].article : "None"}
                                                size="small"
                                                inputProps={register(`line.${index}.article`, {
                                                    required: true,
                                                })}
                                                error={!!errors?.line?.[index]?.article}
                                                helperText={errors?.line?.[index]?.article?.message}
                                            >
                                                {params.societe == 'formations' ? ArticlesFormations.map((article) => {
                                                    return <MenuItem value={article.value}>{article.article}</MenuItem>
                                                }) : ArticlesConsulting.map((article) => {
                                                    return <MenuItem value={article.value}>{article.article}</MenuItem>
                                                })}
                                            </TextField>
                                        </Box>
                                    </td>
                                    <td style={{width: "100%"}}>
                                        <TextField
                                            style={{width: "100%"}}
                                            size="small"
                                            placeholder="designation"
                                            {...register(`line.${index}.designation` as const, {
                                                required: true,
                                            })}
                                            aria-invalid={errors.line?.[index]?.designation ? "true" : "false"}
                                            error={!!errors?.line?.[index]?.designation}
                                            helperText={errors?.line?.[index]?.designation?.message}
                                        />
                                    </td>
                                    <td>
                                        <TextField
                                            size="small"
                                            placeholder="pu"
                                            style={{minWidth: "150px"}}
                                            {...register(`line.${index}.pu` as const, {
                                                valueAsNumber: true,
                                                required: true,
                                            })}
                                            aria-invalid={errors.line?.[index]?.pu ? "true" : "false"}
                                            error={!!errors?.line?.[index]?.pu}
                                            helperText={errors?.line?.[index]?.pu?.message}
                                        />
                                    </td>
                                    <td>
                                        <TextField
                                            style={{minWidth: "80px"}}
                                            size="small"
                                            placeholder="quantite"
                                            {...register(`line.${index}.quantite` as const, {
                                                valueAsNumber: true,
                                                required: true,
                                            })}
                                            aria-invalid={errors.line?.[index]?.quantite ? "true" : "false"}
                                            error={!!errors?.line?.[index]?.quantite}
                                            helperText={errors?.line?.[index]?.quantite?.message}
                                        />
                                    </td>
                                    <td style={{textAlign: "center"}}>
                                        <IconButton aria-label="delete" size="medium" color="error"
                                                    onClick={() => remove(index)}>
                                            <Delete fontSize="medium"/>
                                        </IconButton>
                                    </td>
                                </tr>
                            );
                        })}
                        </tbody>
                    </table>
                    <Total control={control}/>
                    <Button
                        variant="outlined"
                        startIcon={<Add/>}
                        size="small"
                        onClick={() =>
                            append({
                                article: `None`,
                                designation: "",
                                quantite: 0,
                                pu: 0,
                            })
                        }
                    >
                        Ligne
                    </Button>
                    <Box textAlign="center" sx={{pt: "2%", pb: "2%"}}>
                        <SubmitTextField type="submit" value="Modifier la facture"/>
                    </Box>
                </form>
            </div>
        </>
    );
}

const FactureValidator = z.object({
    '@id': z.string(),
    '@type': z.literal('Facture'),
    customer: z.string(),
    customerMatricule: z.number(),
    date: z.string(),
    datetext: z.string(),
    exported: z.boolean(),
    id: z.number(),
    instructor: z.string(),
    instructorMatricule: z.number(),
    lignes: z.array(z.object({
        '@id': z.string(),
        '@type': z.literal('Ligne'),
        article: z.string(),
        designation: z.string(),
        id: z.number(),
        position: z.number(),
        pu: z.string(),
        quantite: z.string(),
    })),
    nbStagiaires: z.number(),
    numFactSage: z.number(),
    session: z.string(),
    societe: z.string(),//z.literal ?
    totalht: z.string(),
});
type Facture = { article: string; designation: string; pu: number; quantite: number }[];
const FactureData = () => {
    const params = useParams();
    return useQuery(
        ["get-facture"],
        async () => {
            const res = await axios.get(`/api/factures/${params.id}`).then((response) => response.data);
            const arr: { article: string; designation: string; pu: number; quantite: number }[] = [];
            const valid = FactureValidator.parse(res);
            valid.lignes.forEach((element, index) => {
                arr.push({
                    article: element.article,
                    designation: element.designation,
                    quantite: Number(element.quantite),
                    pu: Number(element.pu)
                });
            });
            console.log(res);
            return arr;
        },
        {
            onError: (err) => {
                console.error("Fetch API error", err);
            },
        }
    );
};

function Fetch() {
    const {isLoading, isError, data} = FactureData();
    if (isLoading) return <LinearProgress/>;
    if (isError) return <>Error.</>;
    if (data) {
        return <App data={data}/>;
    }
    return null;
}

const queryClient = new QueryClient({
    defaultOptions: {
        //*we want static data
        queries: {
            refetchOnWindowFocus: false,
            refetchOnReconnect: false,
        },
    },
});
export default function Starter() {
    return (
        <QueryClientProvider client={queryClient}>
            <Fetch/>
        </QueryClientProvider>
    );
}
